import DevTools from './DevTools'
import util from './util'
import React, { useState, useContext, Dispatch, SetStateAction } from 'react'
import { fields, useFields } from './modules/FieldsModule';
import { alert, useAlert } from './modules/AlertModule';
import { errors, useErrors } from './modules/ErrorsModule';
import { useValidation } from './modules/ValidationModule';
import { useScrollEffects, scroll, useScroll } from './modules/ScrollModule';
import { unloadHandler, useUnloadHandlerEffects, useUnloadHandler } from './modules/UnloadHandlerModule';
import { options, useOptions } from './modules/OptionsModule';
import { conditions, useConditions, } from './modules/ConditionsModule';
import { payment, usePayment } from './modules/PaymentModule';
import { loading, useLoading } from './modules/Loading';
import { merge } from 'lodash';
import { useFetch } from './hooks/useFetch';
import { useDebounce } from './hooks/useDebounce';
import { useChanged } from './hooks/useChanged';

const initialState = {
    fields,
    conditions,
    errors,
    scroll,
    alert,
    unloadHandler,
    options,
    loading,
    payment,
}

type Config = {
    services:{
        excesses: string,
        options: string,
        sumsInsured: string,
        quote: string,
        smartAddress: string,
        paymentOptions: string,
        paymentAuthorise: string,
        paymentInitiate: string,
        documents: string,
        postcode: string,
        instalments: string,
        details: string,
        findToken: string,
    }
}

let config:Config = {} as any; // Assume this is going to be set


type State = typeof initialState;
type StateHandler = [State, Dispatch<SetStateAction<State>>]

const Context = React.createContext<StateHandler>([initialState, null!]);


function createStore(){
    const Container:React.FC = ({children}) => {
        const state = useState(initialState);
        return <Context.Provider value={state} >{ children }</Context.Provider>
    }

    function useStore(){
        const [ state, setState ] = useContext(Context);

        (window as any)['setState'] = (newState:any) => {
            setState(newState);
            DevTools.update("SET_STATE", newState);
        }
        (window as any)['state'] = setState;

        (window as any)['updateFields'] = (fields:any) => {
            const newState = {
                ...state,
                fields:{
                    ...state.fields,
                    ...fields,
                }
            }
            setState(newState);
            DevTools.update("SET_STATE", newState);
        }
       
        function set<Name extends keyof State>(name:Name){
            return function<Value extends State[Name]>(actionId:string, callback:(value:Value)=>Partial<Value>){
                var currentValue = state[name] as Value;
                var newValue = callback(currentValue);

                setState((state:State) => {
                    const newState = {
                        ...state,
                        [name]: {
                            ...currentValue,
                            ...newValue
                        }
                    }
                    DevTools.update(name + ": " + actionId, newState);
                    return (newState);
                })
            }
        }
        function setAll<Name extends keyof State>(name:Name){
            return function<Value extends State[Name]>(actionId:string, value:Value){
                setState((state:State) => {
                    const newState = {
                        ...state,
                        [name]: value
                    }
                    DevTools.update(name + ": " + actionId, newState);
                    return (newState);
                })
            }
        }
        function get<Name extends keyof State>(name:Name){
            return state[name];
        }

        return { get, set, setAll }
    }

    return {
        Container,
        useStore,
    }
}

const store = createStore();

DevTools.connect();
DevTools.update('initial', initialState);


export default {
    Container: store.Container,
    useStore: () => store.useStore(),
    useFields,
    useAlert,
    useErrors,
    useScroll,
    useUnloadHandler,
    useConditions,
    useValidation,
    useOptions,
    useLoading,
    usePayment,
    useFetch,
    useDebounce,
    useChanged,
    util,
    useStoreEffects: () => {
        useScrollEffects();
        useUnloadHandlerEffects();
    },
    config,
    setConfig<T>(newConfig:T){
        merge(config, newConfig);
        return config as T & typeof config;
    },
}
