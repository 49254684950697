import Common from '../Common'


export type Errors = Record<string, string[]>
export const errors:Errors = {};


export const useErrors = () => {
    const store = Common.useStore();
    const errors = store.get('errors');
    const set = store.set('errors')
    


    function setErrors(errors:Errors|null){
        if(errors === null){
            clear();
        }else{
            store.setAll('errors')('setErrors', errors)
        }
    }

    function setErrorsForField(name:string, fieldErrors:string[]){
        set("setErrorsForField", state => ({
            [name]: fieldErrors
        }))
    }

    function clear(){
        store.setAll('errors')('clear', {})
    }

    function hasErrors(field:string){
        return errors[field] ? errors[field]!.length > 0 : false
    }

    function of(field:string){
        return errors[field]
    }

    return {
        all: errors,
        setErrors,
        clear,
        hasErrors, 
        of,
        setErrorsForField,
    }
}
