import React from 'react'


interface Item {
    title: string,
    url?: string,
}

interface Props {
    items?:Array<Item>
}


const BreadcrumbItem = (item:Item, index:number) => {
    return item.url
        ? <span className="breadcrumbs-item" key={index}><a href={item.url}>{item.title}</a></span>
        : <span className="breadcrumbs-item" key={index}>{item.title}</span>
}

export default (props:Props) => {
    return (
        <div className="breadcrumbs">
            <div className="container">
                <div className="row">
                    <div className="col-12 inner-container">
                        { props.items?.map(BreadcrumbItem)}
                    </div>
                </div>
            </div>
        </div>
    )
}