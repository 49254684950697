import Common from '../Common'
import { PaymentOptionsResult } from '../../common/services/paymentOptions'

// At the very least we will expect payment options
type DataWithDefaults<T> = {
    payment: PaymentOptionsResult,
} & T


export type Options<T> = {
    loaded: boolean,
    data: DataWithDefaults<T>,
};


export const options = {
    loaded: false,
    data: {},
}

export const useOptions = <O>() => {
    type Data = DataWithDefaults<O>;

    const store = Common.useStore();
    const options = store.get('options');
    const set = store.set('options');
    
    function setOptions(data:Data){
        set('setOptions', state => ({
            loaded: true,
            data: data,
        }))
    }

    return {
        ...options,
        setOptions,
        data: options.data as Data,
    }

}