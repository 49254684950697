import { RouteDefinition, RouteSet, /*RouteGroup,*/ RoutesMap } from '../../types'
import { flatten } from 'lodash/fp'


const routeGroup = (route:RouteDefinition) => route[0];
const routeName = (route:RouteDefinition) => route[1];
const routeComponent = (route:RouteDefinition) => route[2];

const fieldNamesForComponent = (component:any) => {
    if(!component.rules) throw("'export const rules' is required on mobule: " + component);
    return Object.keys(component.rules);
}

//const satisfying = (routes:RoutesMap, group:RouteGroup) => routes.filter(route => (routeGroup(route)&group) == group)
//const routeNamesSatisfying = (routes:RoutesMap, group:RouteGroup) => satisfying(routes, group).map(routeName);
//const fieldNamesWhereRoutesSatisfy = (routes:RoutesMap, group:RouteGroup) => flatten(satisfying(routes, group).map(routeComponent).map(fieldNamesForComponent))

const routeIs = (routes:RoutesMap, set:RouteSet) => routes.filter(route => (routeGroup(route) == set))

const fieldNamesWhereRouteIs = (routes:RoutesMap, set:RouteSet) => {
    const relevantRouteData = routeIs(routes, set);
    const relevantRouteComponents = relevantRouteData.map(routeComponent);
    return flatten(relevantRouteComponents.map(fieldNamesForComponent));
}

//const fieldNamesWhereRouteIs = (routes:RoutesMap, set:RouteSet) => flatten(routeIs(routes, set).map(routeComponent).map(fieldNamesForComponent))
const routeNamesWhereRouteIs = (routes:RoutesMap, set:RouteSet) => routeIs(routes, set).map(routeName);

export default {
    //satisfying,
    //routeNamesSatisfying,
    //fieldNamesWhereRoutesSatisfy,

    routeIs,
    fieldNamesWhereRouteIs,
    routeNamesWhereRouteIs,
}
