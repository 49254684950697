const fieldName = (key:string, $:number[]) => {
    let result:string = key;
    for(var i = 0; i < $.length; i++){
        result = result.replace('[x]', '[' + $[i] + ']') as string;
    }
    if(result.indexOf("[x]") != -1) throw("You must specifiy indices to replace array wildcard: " + result);
    return result;
}

export default {
    fieldName
}