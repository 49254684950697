import React from 'react';
import Common from './../common/Common'

type Props = {
    size?: 'fa-lg'
    title?:React.ReactNode,
    message:React.ReactNode
}


export const HelpIcon:React.FC<Props> = ({size, title, message}) => {
    const alert = Common.useAlert();

    if(message == "") return null;
    
    const showAlert = (e:{ stopPropagation:Function, preventDefault:Function }) => {
        
        e.stopPropagation();
        //e.stopImmediatePropagation();
        e.preventDefault();

        var ieEvent:any = e;
        if(ieEvent.returnValue) ieEvent.returnValue = false;
        if(ieEvent.stopImmediatePropagation) ieEvent.stopImmediatePropagation();
        ieEvent.cancelBubble = true;
        //if(e.returnValue)
        
        alert.show({
            title,
            message,
        })

        return false;
    }
    
    return (
        <div className="help-icon" onClick={ showAlert }>
            <i className={`fas fa-info-circle fa-2x ${size?size:""}`} />
        </div>
    )
}

export default HelpIcon