import Common from '../Common';

export type Conditions = Record<string, boolean>;
export const conditions = {
    '/*': false
} as Conditions;


export function useConditions(){
    const store = Common.useStore();
    const conditions = store.get('conditions')
    const set = store.set('conditions');

    function passes(key:string){
        return conditions[key] !== false;
    }

    function setConditions(newConditions:Conditions){
        set("setConditions", state => ({
            ...conditions,
            ...newConditions
        }));
    }

    return {
        all: conditions,
        setConditions,
        passes
    }
}

