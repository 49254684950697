import React, { useRef } from 'react'
import { Collapse } from 'react-bootstrap'
import Common from '../common/Common';
import env from '../env';

type Props = {
    collapsed:boolean
}


export const Legal:React.FC<Props> = ({collapsed}) => {
    const scroll = Common.useScroll();
    const ref = useRef(null);
    const year = new Date().getFullYear();

    const onEntered = () => {
        scroll.show(ref)
    }
    
    return (
        <Collapse in={!collapsed} onEntered={onEntered}>
            <div className="legal" ref={ ref } >
                <div className="container">
                    <div className="row">
                        <div className="col">
                            { env.BRAND === 'flex' &&
                            <>
                                <p>CHUiSAVER Underwriting Agency Pty Ltd (ABN&nbsp;85&nbsp;613&nbsp;645&nbsp;239, AFSL&nbsp;491113) trading as Flex Insurance acts under a binding authority as an agent for certain underwriters at Lloyd’s and QBE Insurance (Australia) Limited (ABN&nbsp;78&nbsp;003&nbsp;191&nbsp;035, AFSL&nbsp;239545), the insurers of the products. </p>
                                <p>Terms, conditions, limits and exclusions apply to the products referred to above. Any advice contained on this website is general advice only and has been prepared without taking into account your objectives, financial situation or needs. Before making a decision to purchase the product or to continue to hold the product we recommend that you consider whether it is appropriate for your circumstances and read the Product Disclosure Statement (‘PDS’), Target Market Determination (‘TMD’) and Financial Services Guide (‘FSG’), which can be obtained by downloading it from the website or by contacting Flex Insurance directly.</p>
                                <p>Copyright © { year } CHUiSAVER Underwriting Agencies Pty Ltd</p>
                            </>
                            }
                            { env.BRAND === 'chu' &&
                            <>
                                <p>CHU Underwriting Agencies Pty Ltd (ABN&nbsp;18&nbsp;001&nbsp;580&nbsp;070, AFS Licence&nbsp;No:&nbsp;243261) acts under a binding authority as agent of the insurer QBE Insurance (Australia) Limited (ABN&nbsp;78&nbsp;003&nbsp;191&nbsp;035, AFS Licence&nbsp;No:&nbsp;239545).</p>
                                <p>Terms, conditions, limits and exclusions apply to the products referred to above. Any advice on this website is general advice only and has been prepared without taking into account your objectives, financial situation or needs. Before making a decision to acquire any product(s) or to continue to hold any product we recommend that you consider whether it is appropriate for your circumstances and read the relevant Product Disclosure Statement (‘PDS’), Financial Services Guide (‘FSG’), and the Target Market Determination (‘TMD’) which can be viewed on this website or obtained by contacting CHU directly.</p>
                                <p>Copyright © {year} CHU Underwriting Agencies Pty Ltd</p>
                            </>
                           }
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

export default Legal;