import React from 'react'
import Grid from './Grid'
import FillButton from '../elements/FillButton'

interface Option<T> {
    description:string,
    value:T
}


interface Props<T> {
    options:Array<Option<T>>,
    selected: T|null,
    onSelected: (value:T)=>void,
    label?: string
}


function NumberPickerButton<T>(option:Option<T>, props:Props<T>, key:number){
    const isSelected = option.value == props.selected;
    const buttonClass = isSelected ? "btn-success" : "btn-outline-secondary";
    return <FillButton label={option.description} buttonClass={buttonClass} callback={ () => props.onSelected(option.value) } key={key} centered={true} />
}



function NumberPicker<T>(props:Props<T>){
    return (
        <div className="number-picker">
            { props.label ? <p>{props.label}</p> : null}
            <Grid
                colsLg={10}
                colsSm={5}
                heightLg="70px"
                heightSm="70px"
                columnGapLg="5px"
                columnGapSm="4px"
                rowGapLg="5px"
                rowGapSm="4px"
            >
                { props.options.map((option, key) => NumberPickerButton(option, props, key)) }
            </Grid>
        </div>
    )
}


export default NumberPicker;