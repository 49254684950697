import Common from '../Common'
import { RuleMap } from '../../types' // TODO: Refactor for better namespacing


export const useValidation = () => {
    const errors = Common.useErrors();
    const fields = Common.useFields();
    const conditions = Common.useConditions();
    const rules = Common.util.validation.rules;

    function validate(rulesMap:RuleMap<any>){
        const toCheck:RuleMap<any> = {};
        const fieldsNames = Object.keys(rulesMap);
        const filteredNames = fieldsNames.filter(fieldName => conditions.passes(fieldName) !== false);
        filteredNames.forEach(fieldName => toCheck[fieldName] = rulesMap[fieldName]);

        const formErrors = rules.getErrors(fields.all, toCheck);
        errors.setErrors(formErrors as any)

        console.log("Errors")
        console.log(errors)
        if(formErrors === null){
            return true
        }
        else return false
    }

    return {
        validate,
        enforce: rules.enforce,
    }

}

