import React, { useRef } from 'react'
import uuid from 'uuid'
import Common from '../common/Common'


type State = [boolean, (value:boolean)=>void];


interface Props {
    field: string | State
    label: string,
    errors?: string[],
}


function Checkbox({ field, label, errors }:Props){
    const uuidName = useRef(uuid()).current;
    const fields = Common.useFields();
    const errorsModule = Common.useErrors();
    const [value, setValue] = fields.state(field);
    const name = typeof field === 'string' ? field : uuidName;
    
    if(typeof field === 'string' && !errors) errors = errorsModule.all[field];
    const hasErrors = errors && errors.length>0;

    return (
        <div className={`custom-control custom-checkbox checkbox form-group ${hasErrors?"is-invalid":""}`}>
            <input
                type="checkbox"
                className="custom-control-input"
                id={name}
                checked={value==true}
                onChange={ e=>setValue(e.target.checked) }
            />
            <label className="custom-control-label" htmlFor={name}><span className="checkbox-label">{label}</span></label>
            { hasErrors &&
                <div className="input-errors">
                    <label className="input-caption invalid-feedback" htmlFor={'input-'+name}>{ errors![0] }</label>
                </div>
            }
        </div>
    )
}

export default Checkbox;