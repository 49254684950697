import React from 'react'

type Props = {
    collapsed:boolean,
    onClick: () => void,
}


const chevron = (collapsed:boolean) => (
    <>
        <span className={collapsed?'d-none':''}><i className={`fas fa-chevron-down`}/></span>
        <span className={!collapsed?'d-none':''}><i className={`fas fa-chevron-up`}/></span>
    </>
)

const ShowLegalButton:React.FC<Props> = ({ collapsed, onClick }) => {
    return (
        <div className="show-legal-button">
            <div className="btn btn-secondary" onClick={onClick}>{ collapsed ? "Legal" : "Legal" } { chevron(collapsed)}</div>
        </div>
    )
}


export default ShowLegalButton;
