import React, { useState } from 'react'
import Legal from '../components/Legal'
import Alert from '../components/Alert'
import Contact from '../components/Contact'
import Header from '../components/Header'
import ShowLegalButton from '../components/ShowLegalButton'
import Feedback from "./Feedback"
import RoutesFeedback from './RoutesFeedback'
import env from'../env'
import Common from '../common/Common'
import { useScrollEffects } from '../common/modules/ScrollModule'
import LoadingIndicator from '../elements/LoadingIndicator'
import { BrowserRouter } from 'react-router-dom'
import Form from './steps/Form'


const getBrandName = () => {
    switch(env.BRAND){
        case "chu": return "CHU";
        case "flex": return "Flex";
    }
}

const documentTitle = () => {
    return "Feedback » " + getBrandName();
}


const AppWithEffects = () => {
    const [ collapsedLegal, setCollapsedLegal ] = useState(true);
    const toggleCollapsed = () => setCollapsedLegal(!collapsedLegal);
    const loading = Common.useLoading();

    useScrollEffects();

    return (
        <>
            <Alert />
            <LoadingIndicator loading={ loading.isLoading } fixed={true} />
            <Header formName="Feedback" />
            <Form/>
            <ShowLegalButton collapsed={collapsedLegal} onClick={toggleCollapsed} />
            <Contact />
            <Legal collapsed={collapsedLegal} />
        </>
    )
}

export default () => {
    document.title = documentTitle();

    return (
        <div className={"app " + "app-brand-"+env.BRAND + " " + "app-form-"+env.FORM} data-testid="app">
            <Feedback.Container>
                <AppWithEffects />
            </Feedback.Container>
        </div>
    )
}