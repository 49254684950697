import { useState } from "react";
import Common from '../Common';


export function useFetch<A extends any[], T>( service:((...args:A)=>Promise<T>), throwOnError:boolean = true) {
    const initialState = {
        data: null as (T|null),
        loading: false,
        count: 0,
        error: null as (Error|null),
    }
    const [state, setState] = useState(initialState)
    const alert = Common.useAlert();

    
    async function load(serviceArgs:A){
        try{
            const response = await service.apply(null, serviceArgs);
            setState(state => ({
                ...state,
                data: response,
                loading: false,
                count: state.count + 1,
            }))
        }catch(e){
            setState({
                ...state,
                loading: false,
                error: !throwOnError ? e : null
            })
            if(throwOnError) alert.showError(e);
        }
    }

    function fetch(...serviceArgs:A) {  
        setState({
            ...state,
            loading: true,
        })
        load(serviceArgs);
    }
    

    return [state.data, state.loading, fetch, state.count, state.error] as const;
}
