import Common from './Common'
import { routes } from './RoutesCommon'
import { Fields } from './FieldsCommon'
import util from './util'


export function routeName(key: typeof routes[number][1]){
    return key;
}

export function fieldName(key:keyof (Fields), ...$:number[]) {
    let result:string = key;
    for(var i = 0; i < $.length; i++){
        result = result.replace('[x]', '[' + $[i] + ']') as string;
    }
    return result;
}

export const enforce = util.validation.rules.enforce;
