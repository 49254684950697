import React, { useState, useRef, useEffect, MutableRefObject } from 'react'
import OnePageForm from '../../components/OnePageForm'
import NumberPicker from '../../elements/NumberPicker'
import Input from '../../elements/Input'
import ButtonContainer from '../../elements/ButtonContainer'
import Checkbox from '../../elements/Checkbox'
import Button from '../../elements/Button'
import Common from '../../common/Common'
import {enforce} from '../../common/HelpersCommon'
import Keyline from '../../elements/Keyline'
import QueryString from 'query-string'
import env from '../../env'
import { generateEmailHTML } from '../../common/util/generateEmailHTML'
import ReCAPTCHA from "react-google-recaptcha";


const name = "name";
const email = "emailState";
const phoneNumber = "phoneNumber";
const message = "message";
const contactRequested = "contactRequested";


const queryExtract = QueryString.extract(window.location.href);
const queryParams = QueryString.parse(queryExtract);

const endpoint = env.ENDPOINT_COMMON_HOST + "/webforms/common/contactus/v2/";

const getToEmailAddress = () => {
    switch(env.BRAND){
        case 'chu': return 'customerfeedback@chu.com.au';
        case 'flex': return 'customerfeedback@flexinsurance.com.au';
        default: throw("Unsupported brand: " + env.BRAND);
    
    }
}

const getFromEmailAddress = () => {
    switch(env.BRAND){
        case 'chu': return 'sales@chu.com.au';
        case 'flex': return 'sales@flexinsurance.com.au';
        default: throw("Unsupported brand: " + env.BRAND);
    
    }
}

const getBrandName = () => {
    switch(env.BRAND){
        case 'chu': return "CHU";
        case 'flex': return "Flex";
        default: throw("Unsupported brand: " + env.BRAND);
    }
}

function getFeedbackLink(){
    switch(env.BRAND){
        case 'flex': return "https://www.google.com.au/maps/place/Flex+Insurance/@-33.8387029,151.2123994,16z/data=!3m1!5s0x6b12ae62d3da6e3b:0x3430cfb07b35eab2!4m8!3m7!1s0x6b12af2b93d24d9f:0x1051be71f95eeedb!8m2!3d-33.8397156!4d151.2070447!9m1!1b1!16s%2Fg%2F11j10dwc86?entry=ttu";
        case 'chu':{
            var state = queryParams.state ? queryParams.state.toLowerCase() : null;
            switch(state){
                case "nsw": return "https://g.page/CHU-NSW/review?rc";
                case "act": return "https://g.page/CHU-NSW/review?rc";
                case "vic": return "https://g.page/CHU-Vic/review?rc"; 
                case "tas": return "https://g.page/CHU-Vic/review?rc";
                case "wa": return "https://g.page/CHU-Perth/review?rc";
                case "sa": return "https://g.page/CHU-SthAust/review?rc";
                case "qld": return "https://g.page/CHU-Qld/review?rc";
                //case "nt": return null;
                default: return "https://g.page/CHU-NSW/review?rc";
            }
        }
    }
}



///////////////////////////////////////////////////////////////////
//
//  Form Base
//
//////////////////////////////////////////////////////////////////
function Form(){
    const [ selected, setSelected ] = useState<number|null>(null);
    const scrollTarget:MutableRefObject<any> = useRef(null);
    const scroll = Common.useScroll();

    const options = [1,2,3,4,5].map(num => ({ description:""+num, value:num }));
    const HAPPY_THRESHOLD = 4;

    const breadcrumbs = [
        { title: "Home", url: env.ERROR_REDIRECT },
        { title: "Feedback" }
    ];

    useEffect(() => {
        if(scrollTarget != null && selected != null){
            scroll.toTopOf(scrollTarget, 16);
        }
    }, [scrollTarget, selected])

    return (
        <OnePageForm breadcrumbItems={breadcrumbs}>
            <h2>We value your feedback</h2>
            <h3>How happy are you with the service you received from { env.BRAND==="chu" ? 'CHU Underwriting Agencies' : 'Flex Insurance'}?</h3>
            <NumberPicker options={options} selected={selected} onSelected={ setSelected } label="Select a number (1 = Unhappy, 5 = Very happy)" />
            
            { selected == null ? null :
                <>
                    <Keyline />
                    <div ref={scrollTarget}>
                        { selected! >= HAPPY_THRESHOLD ? <HappyResult /> : <UnhappyResult rating={selected}/> }
                    </div>
                </>
            }
        </OnePageForm>
    )
}

///////////////////////////////////////////////////////////////////
//
//  Happy result
//
//////////////////////////////////////////////////////////////////
const HappyResult = () => {
    function goToReviewPage(){
        var feedbackLink = getFeedbackLink();
        if(feedbackLink != null){
            window.open(feedbackLink, '_blank');
        }
    }
    
    return (
        <div>
            <h3>We are so glad you are satisfied with our service.</h3>
            <b>Customer satisfaction is extremely important to us, so we'd love to hear your feedback on how you feel about your recent service! If you have a few minutes, we would really appreciate a quick Google review.</b> <b>To submit your review, click the link below and let us know what you think!</b>
            <br />
            <div className="text-center">
                <img className="google-bussiness-logo" src="./assets/google-my-business-logo.png" alt="Google review logo"/>
                <ButtonContainer>
                    <Button label="Go to review page" type="primary" callback={goToReviewPage}/>
                </ButtonContainer>
            </div>
        </div>
    )
}


///////////////////////////////////////////////////////////////////
//
//  Unhappy result
//
//////////////////////////////////////////////////////////////////
const UnhappyResult:React.FC<{ rating:number }> = ({rating}) => {
    const validation = Common.useValidation();
    const loading = Common.useLoading();
    const alert = Common.useAlert();
    const fields = Common.useFields();
    const errors = Common.useErrors();
    const [recaptcha, setRecaptcha] = useState<string|null>(null);
    const [requestData, requestLoading, fetchEndpoint, requestCount, requestError] = Common.useFetch((recaptcha:string) => endpointRequest(recaptcha));

    const recaptchaRef = useRef() as MutableRefObject<ReCAPTCHA>;

    useEffect(() => {
        if(requestData != null){
            loading.endLoading();

            let successAlertMessage:JSX.Element;
            switch(env.BRAND){
                case "chu": successAlertMessage = <div>Thank you for your feedback, it helps us improve what we do. One of our Customer Service team members will be in contact with you shortly to work through your feedback if required. For anything urgent please do not hesitate to call us on <a href={`tel:1300289248`}>1300&nbsp;289&nbsp;248</a> (9am-8pm AEDT) or <a href="mailto:sales@chu.com.au">sales@chu.com.au</a></div>; break;
                case "flex": successAlertMessage = <div>Thank you for your feedback, it helps us improve what we do. One of our customer service team members will be in contact with you shortly to work through your feedback if required. For anything urgent please do not hesitate to call us on <a href={`tel:${env.CONTACT_PHONE}`}>1300&nbsp;001&nbsp;293</a> (9am - 7pm AEST) or <a href="mailto:sales@flexinsurance.com.au">sales@flexinsurance.com.au</a></div>; break;
                default: throw("Unsupported brand: " + env.BRAND);
            }
    
            alert.show({
                title: "Thank you",
                message: successAlertMessage,
                redirect: env.ERROR_REDIRECT
            })
        }
    }, [requestData])


    useEffect(() => {
        if(requestLoading) loading.beginLoading();
        else loading.endLoading();
    }, [requestLoading]);
    
    async function endpointRequest(recaptcha:string){
        console.log("Request endpoint: " + recaptcha)
        const htmlMessage = generateEmailHTML({
            'Name': fields.value(name),
            'Rating': rating,
            'Email': fields.value(email),
            'Phone number': fields.value(phoneNumber),
            'Contact me': fields.value(contactRequested) ? "Yes" : "No",
            'Message': fields.value(message)
        })

        let brand:String;
        switch(env.BRAND){
            case 'chu': brand = "CHU"; break;
            case 'flex': brand = "CHUiSAVER"; break;
            default: throw("Unsupported brand: " + env.BRAND);
        }

        const body = {
            topic: "Feedback received from: " + fields.value(name) + " for " + getBrandName(),
            message: htmlMessage,
            toEmailAddress: getToEmailAddress(),
            fromEmailAddress: getFromEmailAddress(),
            brand: brand,
            recaptcha: recaptcha,
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });

        const responseData = await response.json();

        return responseData;
    }

    async function submit(){
        console.log("Validating")
        const valid =  validation.validate({
            [ name ] : [
                enforce.required('Required'),
                enforce.name("Must be a valid name. The name field can only contain , . - ' and space as special characters."),
                enforce.lengthGreaterThan(1)('Must be at least 2 characters'),
                enforce.lengthLessThan(51)("Must be less than 50 characters")
            ],
            [ email ]: [
                enforce.required('Required'),
                enforce.email('Must be a valid email'),
            ],
            [ phoneNumber ]: [
                enforce.optional(""),
                enforce.number("Must only include numerical characters"),
                enforce.lengthLessThan(11)("Must be 10 or fewer digits"),
            ],
            [ message ] : [
                enforce.required('Required'),
                enforce.lengthLessThan(3001)("Must be less than 3000 characters"),
            ],
            [ contactRequested ] : [],
        })

        if(!valid) return

        const token = await (recaptchaRef.current as any).executeAsync();
        if(!token){
            window.alert("Invalid Recaptcha")
        }else{
            console.log("Valid recaptcha")
            loading.beginLoading();
            await fetchEndpoint(token);
        }
    }


    function onRecaptchaChange(value:string|null) {
        console.log("Recaptcha changed: " + value)
        if(recaptcha != null) return;
        setRecaptcha(value);
    }

    return (
        <div>
            <h3>Sorry to hear that you haven't had a great experience.</h3>
            <b>We pride ourselves on our service, so please let us know how we can make this better.</b>
            <br />
            <br />
            <Input label="Name" type="text" field={name} />
            <Input label="Email address" type="email" field={email} />
            <Input label="Phone number (optional)" type="tel" field={phoneNumber} />
            <Input label="Message/question" type="textarea" field={message} />
            <Checkbox label="Please contact me about this feedback" field={contactRequested} />
            <small>We guarantee 100% privacy. Your information will not be shared.</small>
            <br />
            <br />
            
            <ButtonContainer>
                <Button label="Submit" type="primary" callback={submit}/>
            </ButtonContainer>

            <div style={{ paddingTop:120 }}>
                <ReCAPTCHA ref={recaptchaRef} badge='bottomright' sitekey={env.RECAPTCHA} size="invisible" onChange={ setRecaptcha }/>
            </div>
        </div>
    )
}

export default Form;