import React, { useRef, useEffect } from 'react'
import Common from '../common/Common'
import uuid from 'uuid'
import InputIcon from './InputIcon'

import CurrencyFormatter from 'currency-formatter'


export type Type = // Custom types
            | "dollar"
            | "postcode"
            | "address"
            | "year"
            | "percent"
            | "textarea"
            // Native types
            | "text"
            | "search"
            | "url"
            | "tel"
            | "password"
            | "number"
            | "datetime-local"
            | "date"
            | "month"
            | "week"
            | "time"
            | "email"

type State<T> = [T, (value:T)=>void];

export type Props<T> = {
    type: Type,
    //state?: [T, (value:T)=>void],
    errors?: string[],
    field: string | State<T>,
    label?: React.ReactNode,
    caption? : React.ReactNode,
    children?: React.ReactNode,
    size?: "" | "size-50",
    center?:boolean,
    fontSize?: "" | "large",
    placeholder?: string,
    disabled?: boolean,
    onClick?: () => void,
}



const getInputType = (type:Type) => {
    switch(type){
        case "dollar": return "text";
        case "postcode": return "number"; 
        case "address": return "text";
        case "email": return "email";
        case "date": return "text"; // Overriding
        default: return type;
    }
}

const getInputPattern = (type:Type) => {
    switch(type){
        case "dollar"   : return '[0-9]*'
        case "postcode" : return '[0-9]*'
        case "year"     : return '[0-9]*'
        case "percent"  : return '[0-9]*'
        case "tel"      : return '[0-9]{3}-[0-9]{3}-[0-9]{4}' 
        default: return '';
    }
}

const getInputMode = (type:Type) => {
    switch(type){
        case "dollar"   : return "numeric"
        case "postcode"   : return "numeric"
        case "year"   : return "numeric"
        case "percent"   : return "numeric"
        case "email"   : return "email"
    }
}

function maskState(type:Type, state:[any, (value:any)=>void]):[any, (value:string)=>void]{
    const value:any = state[0];
    const set = state[1];

    switch(type){
        case 'number':
            return [
                value,
                (value:string) => set(fromNumber(value)),
            ]
        case 'dollar':
            return [
                fromCurrency(value),
                (value:string) => set(toCurrency(value))
            ]
        case 'date':
            return [
                value,
                (value:string) => set(fromDate(value))
            ]
        case 'year':
            return [
                value,
                (value:string) => set(fromYear(value))
            ]
        case 'postcode':
            return [
                value,
                (value:string) => set(fromPostcode(value))
            ]
        case 'percent':
            return [
                value,
                (value:string) => set(fromPercent(value))
            ]
         default: return state;
    }
}



function Input<T>({type, label, field, caption, center, errors, children, placeholder, onClick, disabled=false, size="", fontSize=""}:Props<T>){
    const uuidName = useRef(uuid()).current;
    const fields = Common.useFields();
    const scroll = Common.useScroll();
    const conditions = Common.useConditions();
    const errorsModule = Common.useErrors();
    const fieldGroupRef = useRef(null);

    const name = typeof field === 'string' ? field : uuidName;

    useEffect(() => {
        if(typeof field === 'string'){
            if(errorsModule.all[field]){
                scroll.ifNoneAbove(fieldGroupRef, 26);
            }
        }
    }, [errorsModule])
    
    /*
    const state:State<G,S> = typeof field === 'string'
                         ? fields.anyState(field)
                         : field;
    */
    const state = fields.state(field);

    let [ value, setValue ] = maskState(type, state!);
    if(value == null) value = "";


    if(typeof field === 'string' && !errors) errors = errorsModule.all[field];

    const hasErrors = errors && errors.length>0;
    const hasChildren = children && React.Children.count(children);
    
    const visible = typeof field !== 'string' || (typeof field === 'string' && conditions.passes(field) !== false);
    if(!visible) return null;

    return (
        <div className={`input form-group ${size} ${center?"centered":""} ${fontSize}`} ref={fieldGroupRef} onClick={ () => onClick && onClick() }>
            { label &&
            <label className="main-label" htmlFor={"input-"+name}>{ label }</label>
            }
            <div className={`input-group ${hasErrors?"is-invalid":""}`}>
                <InputIcon type={type} position="left" />
                { hasChildren ? children :
                ( type == "textarea"
                ? <textarea 
                    value={"" + value}
                    rows={4}
                    inputMode={ getInputMode(type) }
                    id={"input-"+name}
                    className={`form-control ${hasErrors?"is-invalid":""} ${onClick ? "with-onClick" : ""}`}
                    onChange={(event:any) => setValue(event.target.value)}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                : <input 
                    value={"" + value}
                    pattern={ getInputPattern(type) }
                    inputMode={ getInputMode(type) }
                    id={"input-"+name}
                    type={getInputType(type)}
                    className={`form-control ${hasErrors?"is-invalid":""} ${onClick ? "with-onClick" : ""}`}
                    onChange={(event:any) => setValue(event.target.value)}
                    placeholder={placeholder}
                    disabled={disabled}
                />)
                }
                <InputIcon type={type} position="right" />
                { !hasErrors ? null :
                    <div className="input-errors">
                        <label className="input-caption invalid-feedback" htmlFor={'input-'+name}>{ errors![0] }</label>
                    </div>
                }
                { caption && <label className="input-caption" htmlFor={'input-'+name}><small>{ caption }</small></label> }
            </div>
        </div>
    )
}


export default Input;



/// Masking

function toCurrency(value:string){
    value = stripNonNumberCharacters(value);
    if(value === "" || value === null || value===undefined) return value;
    return parseFloat(value)
}


function fromNumber(value:string){
    value = stripNonNumberCharacters(value);
    if(value === null || value === "" || value===undefined) return value;
    return asNumber(value);
}


function fromCurrency(value:string){
    value = stripNonNumberCharacters(value);
    if(value === null || value === "" || value===undefined) return value;
    const num = asNumber(value);
    return CurrencyFormatter.format(num, {
        thousand: ",",
        precision: 0
    })
}

function fromYear(value:string){
    value = stripNonNumberCharacters(value);
    if(value === null || value===undefined || value === "") return value;
    const sliced = value.slice(0, Math.min(4, value.length));
    return parseInt(sliced);
}

function fromPostcode(value:any){
    if(value === null || value===undefined || value === "") return value;
    const num = stripNonNumberCharacters(value);
    return num.slice(0, Math.min(4, num.length));
}

function fromPercent(value:any){
    if(value === null || value===undefined || value === "") return value;
    return asNumber(stripNonNumberCharacters(value));
}

function fromCurrencyDecimal(value:any){
    value = stripNonDateCharacters(value)
    if(value === null || value === "" || value===undefined) return value;
    return CurrencyFormatter.format(value, {
        thousand: ",",
        precision: 2
    })
}

function fromDate(str:string){
    str = stripNonDateCharacters(str)
    if(str === "" || str === null || str===undefined) return str;

    var sanitised = ""
    for(var i = 0; i < str.length; i++){
        if(i === 2 || i === 5){
            if(str[i] === "/") sanitised += "/"
            else sanitised += "/" + str[i]
        }else{
            if(str[i] !== "/") sanitised += str[i]
        }
       
    }

    sanitised = sanitised.slice(0, 10)
    return sanitised
}


export function asNumber(str:string){
    if(typeof str === 'number') return str;
    str = stripNonNumberCharacters(str);
    if(str === "" || str === null || str==undefined) throw new Error("Cannot return number");
    return parseFloat(str);
}


export function stripNonNumberCharacters(str:string){
    if(typeof str !== 'string') return str;
    if(str === null || str === "" || str===undefined) return str;
    return str.replace(/\D/g, '')
}


export function stripNonDateCharacters(str:string){
    if(str === null || str === undefined || str==="") return str;
    return str.replace( /([^0-9/\//])/g, '' )
}
