export enum Form {
    RESI = "resi",
    CONTENTS = "contents",
    LANDLORDS = "landlords",
    FEEDBACK = "feedback",
    INSTALMENTS = "instalments",
}

export enum Brand {
    CHU = "chu",
    FLEX = "flex",
}

export enum Environment {
    TEST = "test",
    STAGE = "stage",
    PRODUCTION = "production",
}

export enum NodeEnvironment {
    TEST = "test",
    DEVELOPMENT = "development",
    PRODUCTION = "production",
}