import React from 'react';
import env from '../env';

type Props = {
    formName:string
}

const getLogo = () => {
    switch(env.BRAND){
        case 'chu': return require('../assets/chu/logos/logo-chu-vert.png').default;
        case 'flex': return './assets/flex/flex-insurance-color.svg';
        default: throw(new Error("Could not find logo"));
    }
}


const getAltTag = () => {
    switch(env.BRAND){
        case 'chu': return "CHU logo";
        case 'flex': return "Flex logo";
        default: throw(new Error("Could not find logo"));
    }
}

const Header:React.FC<Props> = ({formName}) => (
    <header>
        <a href={env.ERROR_REDIRECT}>
            <img className="logo" alt={getAltTag()} src={ getLogo() }/>
        </a>
        <div className="form-name">{ formName }</div>
    </header>
)

export default Header;


/*
const getLogo = () => {
    switch(env.BRAND){
        case 'chu': return require('../assets/chu/logos/logo-chu-vert.png');
        case 'flex': return require('../assets/flex/logos/flex-insurance-color.svg');
        default: throw(new Error("Could not find logo"));
    }
}

const Header:React.FC<Props> = ({formName}) => (
    <header>
        <div className="logo-container">
            <img className="logo" src={ getLogo() } alt="logo" />
        </div>    
        <FormName name={formName} />
        <button type="button" className="btn btn-lg btn-outline-secondary btn-contact">Contact us</button>
        <button type="button" className="btn btn-sm btn-outline-secondary btn-contact">Contact us</button>
    </header>
)
*/

