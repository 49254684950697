import React, { FunctionComponent, ReactNode } from 'react';
import DividerBar from '../elements/DividerBar';
import Breadcrumbs from '../elements/Breadcrumbs';


type Props = {
    breadcrumbItems?:Array<{
        title: string,
        url?: string,
    }>
}


const OnePageForm:FunctionComponent<Props> = ({ breadcrumbItems, children }) => {
    return (
        <div className="one-page-form">
            <DividerBar />
            <Breadcrumbs items={breadcrumbItems} />
            <div className="content-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default OnePageForm;