import React, { forwardRef } from 'react';

type Props = {
    label: React.ReactNode,
    type: 'primary' | 'secondary' | 'outline-secondary' | 'success',
    size?: 'small' | 'regular',
    callback: () => void,
    icon?: string,
    iconLeft?: string,
    block?:boolean,
    className?:string,
    disabled?:boolean,
}

const button:React.FunctionComponent<Props> = ({label, disabled, icon, iconLeft, type, block, className, callback, size='regular'}, ref) => (
    <button type="button"
            ref={ref}
            disabled={disabled}
            onClick={ e => callback() }
            className={`custom-button btn btn-${type} ${block?"block":""} ${className?className:""} size-${size}`}>
        {iconLeft && <span className="icon-left float-left"><i className={iconLeft} /></span>} {label} {icon && <span className="icon-right float-right"><i className={icon} /></span>}
    </button>
)

export default forwardRef(button);
