import Common from '../Common';

type ResultSuccess = {
    successful:true,
    policyNumber:string,
}

type ResultFail = {
    successful:false,
    reason:string,
}

type Payment = {
    result: null | ResultSuccess | ResultFail
}

export const payment:Payment = {
    result: null
}


export const usePayment = () => {
    const store = Common.useStore();
    const payment = store.get('payment');
    const set = store.set('payment');
    
    function setResult(result:Payment['result']){
        set('setResult', state => ({
            result
        }))
    }

    return {
        setResult,
        result: payment.result
    }

}
