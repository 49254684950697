import { useEffect, useState } from 'react'


export function useChanged (deps:any[], callback:()=>void){
    const [ changed, setChanged ] = useState(false);

    useEffect(() => {
        if(changed) callback();
        else setChanged(true);
    }, deps);

}