import { MutableRefObject, useEffect } from 'react'
import Common from '../Common'
import env from '../../env'


export type Scroll = {
    target: null | {
        y: number,
        position: 'top' | 'bottom',
        offset: number,
        animate: boolean,
    }
}


export const scroll:Scroll = {
    target: null,
};


export function useScrollEffects(){
    const store = Common.useStore();
    const state = store.get('scroll');

    useEffect(() => {
        if(env.NODE_ENV === 'test') return;
        
        const target = state.target;

        if(target !== null){
            const top = target.position === 'top'
                    ? target.y - target.offset
                    : target.y - window.innerHeight + target.offset;

            window.scrollTo({
                top,
                behavior: target.animate ? 'smooth' : "auto"
            })
        }
    }, [state]);
}


export const useScroll = () => {
    const store = Common.useStore();
    const state = store.get('scroll');
    const set = store.set('scroll');
    

    function toTop(animate = true){
        set('toTop', state => ({
            target: {
                position: 'top',
                offset: 0,
                y: 0,
                animate,
            }
        }))
    }

    function ifNoneAbove(target:MutableRefObject<any>,  offset = 0, animate=true){
        const y = target.current.getBoundingClientRect().top + window.scrollY;
        if(!state.target || state.target.y > y){
            toTopOf(target, offset, animate)
        }
    }

    // Scroll the top of the screen to the top of the target
    function toTopOf(target:MutableRefObject<any>, offset = 0, animate = true){
        const y = target.current.getBoundingClientRect().top + window.scrollY;
        set('toTopOf', state => ({
            target:{
                position: 'top',
                offset,
                y,
                animate,
            }
        }))
    }

    // Scroll the bottom of the screen the bottom of the target
    function toBottomOf(target:MutableRefObject<any>, offset = 0, animate = true){
        const y = target.current.getBoundingClientRect().bottom + window.scrollY;
        set('toBottomOf', state => ({
            target: {
                position: 'bottom',
                offset,
                y,
                animate,
            }
        }))
    }

    function toBottomOfScreen(){
        window.scrollTo(0, document.body.scrollHeight);
    }

    // If the target is not on screen, scroll to make it visible
    function show(target:MutableRefObject<any>, offset:number = 0, animate = true){
        const rect = target.current.getBoundingClientRect();
        const topVisible = rect.top-offset-window.scrollY > 0;
        const bottomVisible = rect.bottom+offset < window.innerHeight;
        if(!topVisible) toTopOf(target, offset, animate);
        if(!bottomVisible) toBottomOf(target, offset, animate);
    }

    return {
        toTop,
        toTopOf,
        toBottomOf,
        show,
        toBottomOfScreen,
        ifNoneAbove,
    }
}