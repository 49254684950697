import Common from '../Common'


export type Loading = {
    count:number,
    isLoading: boolean,
}


export const loading:Loading = {
    count: 0,
    isLoading: false,
};


export function useLoading(){
    const store = Common.useStore();
    const loading = store.get('loading');
    const set = store.set('loading');


    function beginLoading(){
        set('beginLoading', state => ({
            count: loading.count + 1,
            isLoading: true,
        }))
    }

    function endLoading(){
        set('endLoading', state => ({
            count: loading.count - 1,
            isLoading: loading.count <= 1 ? false : true,
        }))
    }

    return {
        isLoading: loading.isLoading,
        beginLoading,
        endLoading,
    }

}
