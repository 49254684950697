import { Brand, Form, Environment, NodeEnvironment } from './envConfig'
import { getEnvironmentValue as getValue } from './envUtil'

const nodeEnv = getValue(process.env, 'NODE_ENV', NodeEnvironment) as NodeEnvironment;

export default {
    ENVIRONMENT: getValue(process.env, 'REACT_APP_ENVIRONMENT', Environment) as Environment,
    NODE_ENV: nodeEnv,
    FORM: getValue(process.env, 'REACT_APP_FORM', Form) as Form,
    BRAND: getValue(process.env, 'REACT_APP_BRAND', Brand) as Brand,
    ENDPOINT_HOST: getValue(process.env, 'REACT_APP_ENDPOINT_HOST'),
    ENDPOINT_COMMON_HOST: getValue(process.env, 'REACT_APP_ENDPOINT_COMMON_HOST'),
    ENDPOINT_INSTALMENTS_HOST: getValue(process.env, 'REACT_APP_INSTALMENTS_HOST'),
    BRAND_STYLE: getValue(process.env, 'REACT_APP_BRAND_STYLE'),
    ROOT_COMPONENT: getValue(process.env, 'REACT_APP_ROOT_COMPONENT'),
    ERROR_MESSAGE: getValue(process.env, 'REACT_APP_ERROR_MESSAGE'),
    ERROR_REDIRECT: getValue(process.env, 'REACT_APP_ERROR_REDIRECT'),
    CONTACT_EMAIL: getValue(process.env, 'REACT_APP_CONTACT_EMAIL'),
    CONTACT_PHONE: getValue(process.env, 'REACT_APP_CONTACT_PHONE'),
    CONTACT_PHONE_READABLE: getValue(process.env, 'REACT_APP_CONTACT_PHONE_READABLE'),
    CONTACT_LINKED_IN: getValue(process.env, 'REACT_APP_CONTACT_LINKED_IN'),
    GMT: getValue(process.env, 'REACT_APP_GMT'),

    DEBUG_UNBLOCK_ROUTES: false || (nodeEnv === 'development'),
    DEBUG_SUPRESS_ERROR_ALERTS: false && (nodeEnv === 'development'),
    DEBUG_SUPRESS_UNLOAD_WARNING: false && (nodeEnv === 'development'),

    RECAPTCHA: getValue(process.env, 'REACT_APP_RECAPTCHA')
}
