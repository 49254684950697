import fields from './fields'
import object from './object'
import routes from './routes'
import rules from './validation/rules'

export default {
    fields,
    object,
    routes,
    validation:{
        rules,
    }
}