import React from 'react'
import Common from '../common/Common'
import { Modal, Button } from 'react-bootstrap'
import 'react-bootstrap/Modal'


type Props = {}


const Alert:React.FunctionComponent<Props> = () => {
    const alert = Common.useAlert();
    const state = alert.state;

    const onOk = () => {
        if(alert.state.info.redirect){
            window.location.href = alert.state.info.redirect;
        }else{
            alert.hide();
        }
    }

    return (
        <Modal id="app-modal" show={ state.show }>
            { state.info.title && 
            <Modal.Header>
                <Modal.Title>{ state.info.title }</Modal.Title>
            </Modal.Header>
            }
            <Modal.Body>{ state.info.message }</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={ onOk }>{ state.info.buttonLabel || "Ok" }</Button>
            </Modal.Footer>
        </Modal>
    )
}


export default Alert;
