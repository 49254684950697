import React from 'react';
import HelpIcon from './HelpIcon'

type Props = {
    callback:() => void
    label: React.ReactNode,
    buttonClass: string,
    icon?: string,
    centered?: boolean,
    help?:string
}


const getIcon = (icon?:string) => {
    return icon
        ? <div className="fill-button-icon"><i className={icon}></i></div>
        : null
}

const FillButton:React.FunctionComponent<Props> = ({ callback, label, icon, buttonClass, centered, help }) => (
    <button type="button" className={`btn fill-button ${buttonClass} ${icon?"with-icon":""}`} onClick={ callback }>
        <div className="icon-container">
            { getIcon(icon) }
        </div>
        <div className={`fill-button-contents ${centered?"centered":""}`}>
            <div className="fill-button-label">{ label }</div>

            { !!help && 
            <div className="fill-button-info-body">{ help }</div>
            }

            { !!help && 
            <div className="fill-button-info">
                <HelpIcon message={help} />
            </div>
            }
        </div>
        
    </button>
)

export default FillButton;
