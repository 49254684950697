export function generateEmailHTML(fields:Record<string, string|number>){
    return ``+
        `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">` + 
        `<html xmlns="https://www.w3.org/1999/xhtml">` + 
            `<head>`+
            `<title></title>`+
            `<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />`+
            `<meta http-equiv="X-UA-Compatible" content="IE=edge" />`+
            `<meta name="viewport" content="width=device-width, initial-scale=1.0 " />`+
            `</head>`+
            `<body>`+
                Object.keys(fields).map(field => `<p><b>${field}:</b> ${fields[field]}</p>`).join(" ") +
            `</body>`+
        `<html>`
}
