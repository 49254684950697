import React from 'react';
import ReactDOM from 'react-dom';

import '@fortawesome/fontawesome-free/js/all.js';
import 'core-js'; // IE11 Polyfill
import env from './env/'


function logEnv(){
    if(env.ENVIRONMENT !== "production"){
        console.log(process.env);
    }
}


function setupTheme(){
    // This line must reference the env variable directly so
    // that webpack can substitute the string inline
    require(process.env.REACT_APP_BRAND_STYLE!);
}


function getRootComponent():any{
    // This line must reference the env variable directly so
    // that webpack can substitute the string inline
    return require(process.env.REACT_APP_ROOT_COMPONENT!).default;
}


function setupApp(){
    var Root = getRootComponent();
    ReactDOM.render(<Root />, document.getElementById('root'));
}


export default {
    logEnv,
    getRootComponent,
    setupTheme,
    setupApp,
}