import init from './init'
import env from './env'

var $ = require('jquery');
var win:any = window;
win.$ = $;

init.logEnv();
init.setupTheme();
init.setupApp();


if(env.ENVIRONMENT === "test"){
    var mod:any = module;
    if (mod["hot"]) {
        mod["hot"].accept()
    }
}
